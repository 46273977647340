/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import { scrollToTop } from '@scandipwa/scandipwa/src/util/Browser';
import history from '@scandipwa/scandipwa/src/util/History';
import { createRef, Suspense } from 'react';

import Image from 'Component/Image';
import { ImageRatio } from 'Component/Image/Image.type';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import { ProductComponent, ProductConfigurableAttributes } from 'Component/Product/Product.component';
import { ProductType } from 'Component/Product/Product.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import { GroupedProductItem } from 'Query/ProductList.type';
import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';
import { Children, ReactElement } from 'Type/Common.type';
import { transitionElementFromTo } from 'Util/ElementTransition/ElementTransition';
import { IndexedConfigurableOption } from 'Util/Product/Product.type';

import expressProduct from '../../style/icons/expressProduct.svg';
import offer_icon from '../../style/icons/offer_icon.svg';
import personalised_icon from '../../style/icons/personalised_icon.svg';
import prescription_card_icon from '../../style/icons/prescription_card_icon.svg';
import subscribe_save from '../../style/icons/subscribe_save.svg';
import { ContentObject, ProductCardComponentProps } from './ProductCard.type';

import './ProductCard.style';

/**
 * Product card
 * @class ProductCard
 /* @namespace TrouperPwa/Component/ProductCard/Component */
export class ProductCardComponent extends ProductComponent<ProductCardComponentProps> {
    static defaultProps: Partial<ProductCardComponentProps> = {
        ...ProductComponent.defaultProps,
        thumbnail: '',
        linkTo: '',
        children: null,
        isLoading: false,
        mix: {},
        renderContent: null,
        hideWishlistButton: false,
        hideCompareButton: false,
        layout: CategoryPageLayout.GRID,
    };

    contentObject: ContentObject = {
        renderCardLinkWrapper: this.renderCardLinkWrapper.bind(this),
        pictureBlock: {
            picture: this.renderPicture.bind(this),
        },
        content: {
            review: this.renderReviews.bind(this),
            productPrice: this.renderPrice.bind(this),
            mainDetails: this.renderMainDetails.bind(this),
            additionalProductDetails: this.renderBrand.bind(this),
        },
    };

    imageRef = createRef<HTMLImageElement>();

    /* eslint-disable */
    className = 'ProductCard';

    __construct(props: ProductCardComponentProps): void {
        super.__construct?.(props);

        this.handleLinkClick = this.handleLinkClick.bind(this);
    }

    handleLinkClick(): void {
        const {
            isPlp,
            device: {
                isMobile,
            },
        } = this.props;

        if (isMobile) {
            const imageToCallback = () => document.querySelector('[data-is-pdp=true]');

            transitionElementFromTo({
                elFrom: this.imageRef.current,
                elToCallback: imageToCallback,
                isCopyCssProperties: true,
            });
        }

        if (!isPlp) {
            scrollToTop();
        }
    }


    //#region PRICE
    renderEmptyProductPrice(): ReactElement {
        return (
            <div
              block="ProductCard"
              elem="PriceWrapper"
              mods={ { isEmpty: true } }
            />
        );
    }

    renderPrice(): ReactElement {
        const {
            getActiveProduct,
            product: {
                type_id: baseType,
            } = {},
            inStock,
        } = this.props;

        const {
            price_range: priceRange,
            type_id: typeId,
        } = getActiveProduct();

        if (!priceRange) {
            return this.renderTextPlaceholder();
        }

        if (baseType === ProductType.CONFIGURABLE && !inStock) {
            return null;
        }

        // If product is not a variant.
        const notConfigured = baseType !== ProductType.CONFIGURABLE || typeId === baseType;

        return super.renderPrice(notConfigured);
    }
    //#endregion

    renderPicture(mix = {}): ReactElement {
        const { product: { id, name }, thumbnail, onLoad,product } = this.props;

        return (
            <>
                <Image
                  imageRef={ this.imageRef }
                  src={ thumbnail }
                  alt={ name }
                  ratio={ ImageRatio.IMG_CUSTOM }
                  mix={ { block: 'ProductCard', elem: 'Picture', mix } }
                  isPlaceholder={ !id }
                  onImageLoad={ onLoad }
                />
                <img
                  style={ { display: 'none' } }
                  alt={ name }
                  src={ thumbnail }
                />
            </>
        );
    }

    renderReviews(): ReactElement {
        const { layout } = this.props;

        return (
            <div
              block="ProductCard"
              elem="Reviews"
              mods={ { layout } }
            >
                { this.renderRatingSummary() }
            </div>
        );
    }

    renderProductCompareButton(): ReactElement {
        const {
            hideCompareButton,
        } = this.props;

        if (hideCompareButton) {
            return null;
        }

        return this.renderCompareButton();
    }

    renderProductCardWishlistButton(): ReactElement {
        const { hideWishlistButton, isWishlistEnabled } = this.props;

        if (hideWishlistButton || !isWishlistEnabled) {
            return null;
        }

        return this.renderWishlistButton();
    }

    renderProductActions(): ReactElement {
        return (
            <div block="ProductCard" elem="ProductActions">
                { this.renderProductCardWishlistButton() }
                { this.renderProductCompareButton() }
            </div>
        );
    }

    renderMainDetails(): ReactElement {
        const { product: { name } } = this.props;

        return (
            <p
              block="ProductCard"
              elem="Name"
              mods={ { isLoaded: !!name } }
            >
                <TextPlaceholder content={ name } length={ TextPlaceHolderLength.MEDIUM } />
            </p>
        );
    }

    renderMainOptions(): ReactElement {
        const { product: { variants } } = this.props;
        return (
            <>
            {variants?.length ? <div block="ProductCard" elem="MainOptions">
                <span block="ProductCard" elem="OptionsBadge">{variants?.length-1}+</span> options
            </div> : <div block="ProductCard" elem="MainOptions">
                <span block="ProductCard" elem="OfferPlaceholder">options</span> 
            </div>}
            
            </>
        );
    }
    
    renderSubscribeSave(): ReactElement {
        const {
            product: {
                attributes: { is_on_subscribe_save: { attribute_value: is_on_subscribe_save = '' } = {} } = {},
            },
            productPrice,
            inStock
        } = this.props;
        return (
            <>
                 <div block="ProductCard" elem="SubscribeCard">
                    {inStock ?   <p block="ProductCard" elem="FinalPricethrough">
                   {typeof productPrice?.price?.discount?.percentOff === 'number' && productPrice?.price?.discount?.percentOff > 0 
                        ? productPrice?.price?.originalPrice?.currency : ""} { " " }
                    {
                    typeof productPrice?.price?.discount?.percentOff === 'number' && productPrice?.price?.discount?.percentOff > 0 
                        ? (Number(productPrice?.price?.originalPrice?.value || 0)).toFixed(2)
                        : ""
                }
                    </p>  : <p block="ProductCard" elem="FinalPricethrough"></p>
                    }
                    
                    {is_on_subscribe_save === "1" ? <img src={subscribe_save} alt="subscribe_save" /> : (<div block="ProductCard" elem="SubscribeCard"><img block="ProductCard" elem="OfferPlaceholder" src={subscribe_save} alt="subscribe_save" /> </div>) }
                    
                    </div>
                </>
                );
    }

        renderNameWithBrand(): ReactElement {
            const {
                product: { 
                    name = '', 
                    attributes: { 
                        brand: { 
                            attribute_value: brandValue = '', 
                            attribute_options = {}
                        } = {} 
                    } = {} 
                }
            } = this.props;
        
            // Retrieve the brand label based on attribute_value as the key
            const brandLabel = attribute_options[brandValue]?.label || '';

            return (
                <div>
                    <p block="ProductCard" elem="BrandWithName">
                        { brandLabel && <span>{brandLabel}</span> }
                        { name }
                    </p>
                </div>
            );
        }
    
    renderMainExpress(): ReactElement {
        const {
            product: {
                attributes: { is_express_delivery: { attribute_value: is_express_delivery = '' } = {} } = {},
            },
            product: {
                attributes: { is_personalized: { attribute_value: is_personalized = '' } = {} } = {},
            },
            product: {
                attributes: { is_on_prescription: { attribute_value: is_on_prescription = '' } = {} } = {},
            },
        } = this.props;

        return (
            <div block="ProductCard" elem="MainExpress">
                {is_personalized === "1" ? 
                <p block="ProductCard" elem="Personalised"> <img src={personalised_icon} alt="personalised_icon" />Personalised</p>
                 : is_on_prescription === "1" ? 
                 <p block="ProductCard" elem="Personalised"> <img src={prescription_card_icon} alt="prescription_card_icon" />Prescription</p> : 
                 <p block="ProductCard" elem="OfferPlaceholder"> <img src={personalised_icon} alt="personalised_icon" />Personalised</p>}
                {is_express_delivery === "1" ?
                <p block="ProductCard" elem="ExpressProduct">
                    <img src={ expressProduct } alt="expressProduct" /> 
                </p> : <p block="ProductCard" elem="ExpressProduct">
                    <img block="ProductCard" elem="OfferPlaceholder" src={ expressProduct } alt="expressProduct" /> 
                </p>
                }
            </div>
        );
    }    

    handleNavigation = () => {
                history.push('/');
    };

    renderMainPrice(): ReactElement {

        const {
            product: {
                attributes: { price: { attribute_value: price = '' } = {} } = {},
            },
            product: {
                attributes: { is_on_subscribe_save: { attribute_value: is_on_subscribe_save = '' } = {} } = {},
            },
            product: {
                attributes: { subscribe_save_price: { attribute_value: subscribe_save_price = '' } = {} } = {},
            },
             productPrice,
             inStock,
        } = this.props;

         // If out of stock, show "Shop Similar Items"

    if (!inStock) {
        return (
            <Link to='#' block="ProductCard" elem="MainPrice">
                <p block="ProductCard" elem="OutOfStockMessage">Shop Similar Items 
                </p>
            </Link>
        );
    }

        return (
            <div block="ProductCard" elem="MainPrice">
                <p block="ProductCard" elem="FinalPrice" className={productPrice?.price?.discount?.percentOff ? 'discounted' : ''}>
         <span block="ProductCard" elem="Currency" className={productPrice?.price?.discount?.percentOff ? 'discountedCurrency' : ''}>{productPrice?.price?.finalPrice?.currency}</span>
                {
                    typeof productPrice?.price?.discount?.percentOff === 'number' && productPrice?.price?.discount?.percentOff > 0 
                        ? (Number(productPrice?.price?.finalPrice?.value || 0)).toFixed(2) 
                        : (Number(productPrice?.price?.originalPrice?.value || 0)).toFixed(2)
                }
               </p>
                {is_on_subscribe_save === "1" && (
                    <p block="ProductCard" elem="OriginalPrice">
                     {subscribe_save_price !== undefined && subscribe_save_price !== null && <span block="ProductCard" elem="CurrencyOriginal">{productPrice?.price?.originalPrice?.currency}</span>}
                     {subscribe_save_price !== undefined && subscribe_save_price !== null ? parseFloat(subscribe_save_price).toFixed(2) : ''}
                </p>
                )}
                
            </div>
        );
    }
    

    renderMainGetOffer(): ReactElement {
        const {
            product: {
                attributes: { is_on_offer: { attribute_value: is_on_offer = '' } = {} } = {},
            },
            product: {
                attributes: { promotional_text: { attribute_value: promotional_text = '' } = {} } = {},
            }
        } = this.props;
        
        return (
            <div block="ProductCard" elem="MainGetOffer">
                {promotional_text !== "" ? (
        <div block="ProductCard" elem="OfferText">
            <img src={offer_icon} alt="offer_icon" /> 
            <div
             dangerouslySetInnerHTML={{ __html: promotional_text }}
            />
        </div>
    ) : (
        <div block="ProductCard" elem="OfferPlaceholder">
                    <div block="ProductCard" elem="OfferText">
            <img src={offer_icon} alt="offer_icon" /> 
            Get <span block="ProductCard" elem="OfferHighlight"> 20%</span> off when you spend a minimum off when
            <span block="ProductCard" elem="OfferHighlight"> AED 500</span>.
        </div>
        </div>
    )}
            </div>
        );
    }

    renderMainTopCardStatus(): ReactElement {
        const {
            inStock,
            product: {
                attributes: { is_new: { attribute_value: is_new = '' } = {} } = {},
            },
            product: {
                attributes: { is_on_deal: { attribute_value: is_on_deal = '' } = {} } = {},
            },
            product: {
                attributes: { is_on_offer: { attribute_value: is_on_offer = '' } = {} } = {},
            },
        } = this.props;

        return (
            <> 
            {!inStock ? <div block="ProductCard" elem="CardTopStatusOutOfStock">
                OUT OF STOCK
            </div> : is_new === "1" ? <div block="ProductCard" elem="CardTopStatus">
                NEW
            </div> :  (is_on_deal === "1" || is_on_offer === "1") ? <div block="ProductCard" elem="CardTopStatusDeal">
                DEAL
            </div> : ""}
            
            </>
        );
    }
    

    renderCardLinkWrapper(children: Children, mix = {}): ReactElement {
        const { linkTo = '', product: { url } } = this.props;

        if (!url) {
            return (
                <div
                  block="ProductCard"
                  elem="Link"
                >
                    { children }
                </div>
            );
        }

        return (
            <Link
              block="ProductCard"
              elem="Link"
              to={ linkTo }
              onClick={ this.handleLinkClick }
              showLoader
              onClickBeforeLoader={ this.handleLinkClick }
              mix={ mix }
            >
              { children }
            </Link>
        );
    }

    requiresConfiguration(): boolean {
        const {
            parameters,
            product: {
                type_id: type,
                options = [],
                items = [],
                links_purchased_separately,
            },
        } = this.props;

        const configureBundle = type === ProductType.BUNDLE;

        const allAttrs = super.getConfigurableAttributes();
        const plpConfigurableAttrs = this.getConfigurableAttributes();

        const isConfigurable = type === ProductType.CONFIGURABLE;

        const configureConfig = isConfigurable && (
            (
                Object.keys(allAttrs).length
                !== Object.keys(plpConfigurableAttrs).length
            )
            || (
                Object.values(plpConfigurableAttrs).some(
                    (value) => value.attribute_values.length === 0,
                )
            )
            || (Object.keys(allAttrs).length > 0 && Object.keys(parameters).length === 0)
        );

        const configureGrouped = type === ProductType.GROUPED
            && (items as GroupedProductItem[]).every(({ qty }) => qty === 0);

        const configureCustomize = options.some(({ required = false }) => required);

        const configureDownloadableLinks = ProductType.DOWNLOADABLE && links_purchased_separately === 1;

        return configureGrouped
            || configureBundle
            || configureConfig
            || configureCustomize
            || configureDownloadableLinks;
    }

    renderAddToCart(): ReactElement {
        const {
            layout,
            showSelectOptionsNotification,
            inStock,
        } = this.props;

        if (!inStock) {
            return (
                <div block="ProductCard" elem="OutOfStock">
                    <button
                    block="AddToCart"
                    mods={ { layout } }
                    onClick={ showSelectOptionsNotification }
                    style={{background:"#B8B7B5"}}
                    >
                        { __('Add to cart') }
                    </button>
                </div>
            );
        }

        return this.renderAddToCartButton(layout);
    }

    renderAddToCartConfigurable(): ReactElement {
        const {
            layout,
            showSelectOptionsNotification,
            inStock,
        } = this.props;

        if (!inStock) {
            return (
                <div block="ProductCard" elem="OutOfStock">
                    <button
                    block="AddToCart"
                    mods={ { layout } }
                    style={{background:"#B8B7B5"}}
                    >
                        { __('Add to cart') }
                    </button>
                </div>
            );
        }

        return (
            <div block="ProductCard" elem="OutOfStock">
                <button
                block="AddToCart"
                mods={ { layout } }
                >
                    { __('Add to cart') }
                </button>
            </div>
        );
    }

    getConfigurableAttributes(): Record<string, IndexedConfigurableOption> {
        const filteredOptions = super.getConfigurableAttributes();

        return Object.fromEntries(
            Object.entries(filteredOptions).filter(
                ([, option]) => {
                    const { attribute_options } = option;

                    if (!attribute_options) {
                        return false;
                    }

                    return Object.values(attribute_options)
                        .some(({ swatch_data: swatchData }) => swatchData);
                },
            ),
        );
    }

    renderVisibleOnHover(): ReactElement {
        const { device } = this.props;

        if (device.isMobile) {
            return null;
        }

        return (
            <>
                { this.renderConfigurableOptions() }
                <div block="ProductCard" elem="Footer">
                    { this.renderAddToCart() }
                    { this.renderProductActions() }
                </div>
            </>
        );
    }

    renderConfigurableOptions(): ReactElement {
        const {
            setActiveProduct,
            parameters,
            product: { type_id: type, variants = [] },
            inStock,
            addToCartTriggeredWithError,
            updateAddToCartTriggeredWithError,
        } = this.props;

        if (
            type !== ProductType.CONFIGURABLE
            || !Object.keys(this.getConfigurableAttributes()).length
            || !inStock
        ) {
            return null;
        }

        return (
            <div
              block="ProductActions"
              elem="AttributesWrapper"
            >
                <Suspense fallback={ null }>
                    <ProductConfigurableAttributes
                      // eslint-disable-next-line no-magic-numbers
                      numberOfPlaceholders={ [2, 4] }
                      updateAddToCartTriggeredWithError={ updateAddToCartTriggeredWithError }
                      addToCartTriggeredWithError={ addToCartTriggeredWithError }
                      mix={ { block: this.className, elem: 'Attributes' } }
                      parameters={ parameters }
                      variants={ variants }
                      updateConfigurableVariant={ setActiveProduct }
                      configurable_options={ this.getConfigurableAttributes() }
                      isContentExpanded
                      inStock={ inStock }
                      showProductAttributeAsLink={ false }
                    />
                </Suspense>
            </div>
        );
    }

    renderCardContent(): ReactElement {
        const { renderContent, product: { name, id, type_id }, isMobile, notifications, cartIteams } = this.props;
         // Safely extract msgText
    const dynamicKey = Object.keys(notifications || {})[0]; // Get the first key
    let msgText =  undefined;

    if(dynamicKey){
        if(notifications[dynamicKey]?.msgType === 'success'){
            msgText = "Added to cart";
        }
        else {
            msgText = notifications[dynamicKey]?.msgText;
        }
    }

    let cartItem = cartIteams;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        let contentToRender;

            if (type_id === 'configurable') {
                contentToRender = this.renderCardLinkWrapper(this.renderAddToCartConfigurable());
            } else {
                contentToRender = this.renderAddToCart();
            }

        return (
            <div block="ProductCard" elem="LinkInnerWrapper" mods={{ loaded: !!name }}>
                {this.renderCardLinkWrapper(
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            <div block="ProductCard" elem="MobileActions">
                                {this.renderMainTopCardStatus()}
                            </div>
                            {this.renderPicture()}
                        </figure>
                    </div>
                        )}
                    <div block="ProductCard" elem="Content">
                       
                        { this.renderMainOptions() }
                        { this.renderMainExpress() }
                        {this.renderCardLinkWrapper(this.renderNameWithBrand())}
                        {this.renderSubscribeSave()}
                        { this.renderMainPrice() }
                        { this.renderMainGetOffer() }
                    </div>
                    <div block="ProductCard" elem="ContentBotton">
                    {(cartItem === id) && msgText ? (
        <div block="ProductCard" elem="NotificationCartToast">
            <p block="ProductCard" elem="AddToCartToast">{msgText}</p>
        </div>
    ) : (
        contentToRender
    )}
                    </div>
            </div>
        );
    }

    renderCardListContent(): ReactElement {
        const {
            children, layout, renderContent,
        } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return this.renderCardLinkWrapper((
            <div block="ProductCard" elem="Link">
                <div block="ProductCard" elem="FigureReview">
                    <figure block="ProductCard" elem="Figure">
                        { this.renderPicture() }
                    </figure>
                </div>
                <div block="ProductCard" elem="Content" mods={ { layout } }>
                    <div block="ProductCard" elem="MainInfo">
                        { this.renderMainOptions() }
                        { this.renderBrand() }
                        { this.renderMainDetails() }
                    </div>
                    <div block="ProductCard" elem="AttributeWrapper">
                        { this.renderAddToCart() }
                        { this.renderProductActions() }
                    </div>
                    <div block="ProductCard" elem="AdditionalContent">
                        { children }
                    </div>
                </div>
            </div>
        ));
    }

    render(): ReactElement {
        const {
            children,
            mix,
            isLoading,
            layout,
        } = this.props;

        if (layout === CategoryPageLayout.LIST) {
            return (
                <li
                  block="ProductCard"
                  mods={ { layout } }
                  mix={ mix }
                >
                    <Loader isLoading={ isLoading } />
                    { this.renderCardListContent() }
                </li>
            );
        }

        return (
            <li
              block="ProductCard"
              mods={ { layout } }
              mix={ mix }
            >
                <Loader isLoading={ isLoading } />
                { this.renderCardContent() }
                <div block="ProductCard" elem="AdditionalContent">
                    { children }
                </div>
            </li>
        );
    }
}

export default ProductCardComponent;
